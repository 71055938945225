import axios from 'axios';
import { mockList, mockUrl } from '@/util/mockConfig';
import eventBus from '@/util/use-event-bus/global-event-bus';
import Notice from './notice.base';
import { showLoading, closeLoading } from './loading';
import goBackLogin from '../router/back-login';
import { getStorage } from './storage';

let showNotice = true;

function createRejection(callback: Function, isShowNotice = true) {
    return (error: any) => {
        if (error.code) {
            if (showNotice) {
                showNotice = false;
                if (error.code.toString() === '2') {
                    eventBus.emit('logOut');
                    Notice.messageBox('alert', error.msg, WordList.TabelMessageBoxAlertRequireFailed, 'error')(() => {
                        showNotice = true;
                        goBackLogin();
                    }, () => {
                        showNotice = true;
                        goBackLogin();
                    });
                } else if (error.code.toString() === '-50') {
                    // eslint-disable-next-line no-irregular-whitespace
                    Notice.messageBox('alert', `The domain has been changed, please log in ${error.Url.toString()}`,
                        WordList.Notice, 'error')(() => {
                        showNotice = true;
                        window.open(error.Url);
                    }, () => {
                        showNotice = true;
                        window.open(error.Url);
                    });
                } else if (error.code.toString() === '10000') {
                    // 上级关闭下级权限时
                    Notice.messageBox('alert', error.msg, WordList.TabelMessageBoxAlertRequireFailed, 'error')(() => {
                        window.location.reload();
                    }, () => {
                        window.location.reload();
                    });
                } else if (isShowNotice) {
                    Notice.messageBox('alert', error.msg, WordList.TabelMessageBoxAlertRequireFailed, 'error')(() => {
                        showNotice = true;
                        callback(error);
                    }, () => {
                        showNotice = true;
                        callback(error);
                    });
                } else {
                    showNotice = true;
                    callback(error);
                }
            } else {
                callback(error);
            }
        } else {
            callback(error);
        }
    };
}

let host = '';
const version = process.env.VUE_APP_VERSION as string;
if (process.env.NODE_ENV === 'development') {
    host = '/api/';
} else {
    host = CONFIG_BACKEND_URL ? `https://${CONFIG_BACKEND_URL}/` : '/';
}

// axios.defaults.baseURL = `${host}apache-v3.0/`;

// 添加请求拦截器
axios.interceptors.request.use((config) => {
    // loadingInstance = ElLoading.service();
    const config2 = { ...config };
    // 6.5.2免密登录需求：通过sessionStorage请求接口
    if (sessionStorage.getItem('token')) {
        config2.headers.common['x-auth-token'] = sessionStorage.getItem('token');
    } else {
        config2.headers.common['x-auth-token'] = localStorage.getItem('token');
    }
    // 后续 v3 接口迁移 v4 接口时，需要在单独设置 header 的地方补充 x-project
    if (config2.url?.includes('v4/')) {
        config2.headers!.common['Content-Type'] = 'application/json';
        config2.headers!.common['x-project'] = config2.headers!.common['x-project'] || getStorage('identityUUID');
    } else {
        config2.headers!.common['Content-Type'] = 'application/x-www-form-urlencoded';
    }
    config2.headers.common['x-community-id'] = localStorage.getItem('communityID');
    config2.headers.common['x-cloud-version'] = version;
    config2.headers.common['x-cloud-lang'] = Lang;
    return config2;
}, (error) => Promise.reject(error));

// 添加响应拦截器
axios.interceptors.response.use((response) => {
    const { data } = response;
    if (data.code !== 0) return Promise.reject(data);
    return data;
}, (error) => Promise.reject(error));

const defaultError = (error?: any): void => {
    console.error(error);
};

type successParam = Function | [Function, boolean];
type errorParam = Function | [Function, boolean];

interface Param {
    [key: string]: any;
}

type PostParam = Param | FormData | [Param | FormData, boolean];
type getParm = Param | [Param, boolean];

function isFunction(fn: successParam) {
    return typeof fn === 'function';
}

class HttpRequest {
    http: any;

    host: string;

    version: string;

    defaultUrl: string;

    constructor() {
        this.http = axios;
        this.host = host;
        this.version = version;
        this.defaultUrl = `${host}apache-v3.0/`;
    }

    private dealParam(params: Param, searchParams: URLSearchParams, keyPrefix = '{0}') {
        Object.keys(params).forEach((key) => {
            if (typeof params[key] === 'string' || typeof params[key] === 'number') {
                searchParams.append(keyPrefix.format(key), params[key]);
            } else if (typeof params[key] === 'object' && params[key] !== null) {
                this.dealParam(params[key], searchParams, `${keyPrefix.format(key)}[{0}]`);
            }
        });
    }

    private getUrl(url: string) {
        // v3开头全部访问新接口
        const reg = new RegExp('^(v3|v4)/');
        if (!reg.test(url)) {
            if (process.env.NODE_ENV === 'development' && mockList.includes(url)) {
                return `${mockUrl}apache-v3.0/${url}`;
            }
            return `${host}apache-v3.0/${url}`;
        }
        if (process.env.NODE_ENV === 'development' && mockList.includes(url)) {
            return `${mockUrl}web-server/${url}`;
        }
        return `${host}web-server/${url}`;
    }

    get(url: string, params: getParm, successCall: Function, errorCall: errorParam = defaultError) {
        const requestUrl = this.getUrl(url);
        let requestParam = params;
        let loading = true;
        if (Array.isArray(requestParam)) {
            [requestParam, loading] = requestParam;
            if ((loading as any) === 'false') {
                loading = false;
            } else if ((loading as any) === 'true') {
                loading = true;
            }
        } else if (requestParam.loading) {
            if ((requestParam.loading as any) === 'false') {
                loading = false;
            } else if ((requestParam.loading as any) === 'true') {
                loading = true;
            }
        }
        // 为了解决BUG的临时方案：55760
        if (url === 'v3/web/community/device/getListForPm'
        || url === 'v3/web/office/device/getListForPm'
         || url === 'v3/web/community/access/getListByMac') {
            loading = false;
        }
        if (loading) {
            showLoading();
        }
        this.http.get(requestUrl, {
            params: requestParam
        }).then((data: object) => {
            if (loading) {
                closeLoading();
            }
            successCall(data);
        }).catch((error: any) => {
            if (loading) {
                closeLoading();
            }
            if (Array.isArray(errorCall) && errorCall[1] === false) {
                createRejection(errorCall[0], false)(error);
                // errorCall[0](error);
            } else if (typeof errorCall === 'function') {
                createRejection(errorCall)(error);
            }
        });
    }

    post(url: string, params: PostParam, successCall: successParam, errorCall: errorParam = defaultError) {
        const requestUrl = this.getUrl(url);
        let requestParam = params;
        let loading = true;
        if (Array.isArray(requestParam)) [requestParam, loading] = requestParam;
        if (loading) {
            showLoading();
        }

        const newParams = new URLSearchParams();
        if (!(requestParam instanceof FormData)) {
            this.dealParam(requestParam, newParams);
        }
        let postData;
        if (url.includes('v4')) {
            postData = params;
        } else if (requestParam instanceof FormData) {
            postData = requestParam;
        } else {
            postData = newParams;
        }
        this.http.post(requestUrl, postData).then((data: any) => {
            if (loading) {
                closeLoading();
            }
            let call = successCall;
            let showTips = true;
            if (!isFunction(successCall)) {
                [call, showTips] = successCall as [Function, boolean];
            }
            // 显示后台返回提示信息
            if (showTips && data.msg) Notice.message(data.msg);
            (call as Function)(data);
        }).catch((error: any) => {
            if (loading) {
                closeLoading();
            }
            if (Array.isArray(errorCall) && errorCall[1] === false) {
                createRejection(errorCall[0], false)(error);
            } else if (typeof errorCall === 'function') {
                createRejection(errorCall)(error);
            }
        });
    }

    // TODO all
}

export default new HttpRequest();
export {
    defaultError,
    successParam,
    errorParam
};
