import GetRequest from '../basic';

const getExportTime = (params: { exportLogType: string}, callback: Function) => {
    GetRequest('v4/web/office/log/pm/getLogExportTime', params, callback);
};

export default null;

export {
    getExportTime
};
